export interface CognitoError {
  code?: string;
  name?: string;
  message?: string;
  response?: {
    data?: {
      error?: {
        message?: string;
      };
    };
  };
}

export default function parseCognitoError(err: CognitoError): string {
  switch (true) {
    case err?.message === null || typeof err?.message === "undefined":
      return "Something went wrong, please contact support@canopyservicing.com.";
    case err?.message?.includes("409"):
      return "An account with the given email already exists.";
    case err?.message === "Incorrect username or password.":
      return "Incorrect username or password.";
    case err?.response?.data?.error?.message === "Organization already exists":
      return "An organization is already registered. If you believe this message was sent in error, please contact support@canopyservicing.com.";
    default:
      return "Something went wrong, please contact support@canopyservicing.com.";
  }
}
